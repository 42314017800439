import React from "react"
import { MobileHeader } from "./MobileHeader"
import { DesktopHeader } from "./DesktopHeader"

type Props = {
  transparent: boolean
}

export const Header = ({ transparent }: Props) => {
  return (
    <>
      <DesktopHeader transparent={transparent} />
      <MobileHeader />
    </>
  )
}
