import React from "react"
import { breakpoints, colors, measure, media } from "../styles"
import { Footer } from "../blocks/Footer"
import { SecondaryHero } from "../blocks/hero/secondary/SecondaryHero"
import styled from "styled-components"
import { Quote } from "../blocks/Quote"
import { PageCommon } from "../components/PageCommon"
import { graphql, useStaticQuery } from "gatsby"
import { ContentContainer } from "../components/ContentContainer"
import Image, { GatsbyImageProps } from "gatsby-image"
import { BackToTopButton } from "../components/BackToTopButton"
import { resolveContent } from "../utils/content"
import { useLanguage } from "../utils/useLanguage"

const i18n = {
  es: {
    quote: "Estamos hechos de historias”",
  },
  en: {
    quote: "We are made of stories”",
  },
}

const Main = styled.main`
  & > div:nth-child(even) {
    background-color: ${colors.gray100};
  }
`

const Content = styled.div`
  & > * + * {
    margin-top: 64px;

    ${media.smallerThan(breakpoints.large)} {
      margin-top: 48px;
    }
  }
`

export const Text = styled.div`
  li:not(:last-child) {
    margin-bottom: ${measure.measure12};
  }

  ${media.smallerThan(breakpoints.medium)} {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const StyledImage = styled(Image).attrs({
  imgStyle: {
    objectFit: "cover",
    objectPosition: "center top",
  },
})<GatsbyImageProps>`
  height: 0;
  width: 100%;
`

export default () => {
  const language = useLanguage()
  const data = useStaticQuery(query)

  return (
    <>
      <PageCommon />
      <SecondaryHero />
      <Main>
        <ContentContainer maxWidth="medium">
          <Content>
            <Text
              dangerouslySetInnerHTML={{
                __html: resolveContent(data.content, "ana", language),
              }}
            />
            <StyledImage
              fluid={data.anaImage.childImageSharp.fluid}
              style={{
                paddingTop: "60%",
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: resolveContent(data.content, "ana-courses", language),
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: resolveContent(
                  data.content,
                  "ana-performances",
                  language
                ),
              }}
            />
          </Content>
        </ContentContainer>
        <ContentContainer maxWidth="medium">
          {/* TODO BRING BACK */}
          {/* <Quote text={i18n[language].quote} /> */}
          <Content>
            <Text
              dangerouslySetInnerHTML={{
                __html: resolveContent(data.content, "gonzalo", language),
              }}
            />
            <StyledImage
              fluid={data.gonzaloImage.childImageSharp.fluid}
              style={{
                paddingTop: "60%",
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: resolveContent(
                  data.content,
                  "gonzalo-theatre",
                  language
                ),
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: resolveContent(
                  data.content,
                  "gonzalo-screen",
                  language
                ),
              }}
            />
          </Content>
        </ContentContainer>
      </Main>
      <Footer />
      <BackToTopButton />
    </>
  )
}

export const query = graphql`
  fragment FluidImage on File {
    childImageSharp {
      fluid {
        base64
        tracedSVG
        aspectRatio
        srcWebp
        srcSetWebp
        originalName
      }
    }
  }

  query {
    anaImage: file(relativePath: { eq: "about/ana.jpg" }) {
      ...FluidImage
    }
    gonzaloImage: file(relativePath: { eq: "about/gonzalo.jpg" }) {
      ...FluidImage
    }
    content: allMarkdownRemark(filter: { fields: { page: { eq: "about" } } }) {
      edges {
        node {
          fields {
            id
            language
          }
          html
        }
      }
    }
  }
`
