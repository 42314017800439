import { Language } from "../types"

export const resolveContent = (
  content: any,
  id: string,
  language: Language
) => {
  const edge = content.edges.find(
    ({ node }: any) =>
      node.fields.id === id && node.fields.language === language
  )
  if (!edge) {
    throw new Error(
      `Failed to find id:${id} with language:${language} in content`
    )
  }
  return edge.node.html
}
