import React from "react"
import styled from "styled-components"
import { Header } from "../header/Header"

const Root = styled.div`
  /* height: 78px; */
  width: 100%;
  overflow: hidden;
  position: relative;
`

export const SecondaryHero = () => {
  return (
    <Root>
      <Header transparent={false} />
    </Root>
  )
}
